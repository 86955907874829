import { Link, useStaticQuery } from "gatsby"
import React from "react"
import { Button } from "semantic-ui-react"
import Image from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/404.css"
const query = graphql`
  {
    file(relativePath: { eq: "404.PNG" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
const NotFoundPage = () => {
  const data = useStaticQuery(query)
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className="fof-container">
        <Image fluid={data.file.childImageSharp.fluid} className="fof-image" />
        <div className="fof-head1">Oh no! Looks like you are lost</div>
        <div className="fof-head2">
          The page you are looking for no longer exits
        </div>
        <Button as={Link} to={`/`} className="fof-button">
          Go back to Home
        </Button>
      </div>
    </Layout>
  )
}

export default NotFoundPage
